import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import Glide from '@glidejs/glide';
import GLightbox from 'glightbox';
import ScrollOut from "scroll-out";
import tippy from 'tippy.js';


// Intiate Vuejs instance
const app = new Vue({
    el: '#app',
    delimiters: ["${","}"],
    components: {
        Breakpoints,
    }
})


// Scripts to fire once dom has loaded
document.addEventListener('DOMContentLoaded', () => {

    function isScrolledIntoView(elm) {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

    // NZSL desktop videos
    const videoContainers = document.querySelectorAll('.nzsl--desktop');

    if (videoContainers) {
        videoContainers.forEach(function(videoContainer) {

            const video = videoContainer.querySelector('.nzsl__video');
            const videoFull = videoContainer.querySelector('.nzsl__full-video');
            const videoPlay = videoContainer.querySelector('.nzsl__btn');

            // Short video hover
            bindEvent(videoContainer, "mouseover", function() {
                video.play();
            });
            bindEvent(videoContainer, "mouseleave", function() {
                video.pause();
                video.currentTime = 0;
            });

            // Play full video
            bindEvent(videoPlay, "click", function() {
                if (videoFull.paused === true) {
                    var playPromise = videoFull.play();

                    if (playPromise !== undefined) {
                        playPromise.then(_ => {
                            videoPlay.classList.add('nzsl__btn--hidden');
                            videoFull.classList.remove('hidden');
                            videoFull.setAttribute('controls', 'controls');
                            video.classList.add('hidden');
                        })
                            .catch(error => {
                                console.log('error');
                            });
                    }
                }
            });
        });

        bindEvent(window, "scroll", function () {
            videoContainers.forEach(function (videoContainer) {
                const videoFull = videoContainer.querySelector('.nzsl__full-video');
                if (videoFull.paused == true && videoFull.currentTime == 0 && !videoFull.classList.contains('hidden') && isScrolledIntoView(videoFull)) {
                    videoFull.play();
                } else if (!videoFull.classList.contains('hidden') && !isScrolledIntoView(videoFull)) {
                    videoFull.pause();
                    videoFull.currentTime = 0;
                }
            });
        });
    }

    // NZSL mobile videos
    const videoMobileContainers = document.querySelectorAll('.nzsl--mobile');

    if (videoMobileContainers) {
        videoMobileContainers.forEach(function(videoMobileContainer) {

            const videoMobilePlay = videoMobileContainer.querySelector('.nzsl__btn');
            const videoMobile = videoMobileContainer.querySelector('.nzsl__full-video');

            // Play full video
            bindEvent(videoMobilePlay, "click", function() {
                if (videoMobile.paused == true) {
                    videoMobilePlay.classList.add('nzsl__btn--hidden');
                    videoMobile.setAttribute('controls', 'controls');
                    videoMobile.play();
                }
            });
        });

        bindEvent(window, "scroll", function () {
            videoMobileContainers.forEach(function (videoContainer) {
                const videoMobilePlay = videoContainer.querySelector('.nzsl__btn');
                const videoFull = videoContainer.querySelector('.nzsl__full-video');
                if ( !isScrolledIntoView(videoFull)) {
                    videoFull.pause();
                    videoFull.currentTime = 0;
                }
                if (videoFull.paused == true && videoFull.currentTime == 0 && videoMobilePlay.classList.contains('nzsl__btn--hidden') && isScrolledIntoView(videoFull)) {
                    videoFull.play();
                }
            });
        });
    }


    // Scroll out - scroll-based trigger for animations
    ScrollOut({
        once: true,
    });


    // Search modal
    const searchTriggers = document.querySelectorAll('.js-search-btn');
    const search = document.querySelector('.search');
    const searchInput = document.querySelector('.search__input');
    var searchOpen = false;

    if(searchTriggers){
        searchTriggers.forEach(function(e) {
            const searchTrigger = e;
            bindEvent(searchTrigger, "click", function() {
                document.body.classList.toggle('modal-open');
                search.classList.toggle('search--active');
                searchInput.focus();
                console.log(searchOpen);
                searchOpen = searchOpen ? false : true;
                console.log(searchOpen);
            }, false);
        });
    }

    // Search modal - escape key function
    document.onkeydown = function(event) {
        event = event || window.event;
        if (event.keyCode == 27 && searchOpen) {
            document.body.classList.remove('modal-open');
            search.classList.remove('search--active');
        }
    };


    // G Lightbox Gallery
    const gallery = GLightbox({
        selector: '.glightbox',
        skin: 'custom',
        videosWidth: '40%',
        plyr: {
            config: {
                ratio: '4:4'
            }
        },
        svg: {
            close: '<svg aria-hidden="true" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="23" y1="23" x2="3" y2="3" stroke="white" stroke-width="4" stroke-linecap="round"/><line x1="3" y1="23" x2="23" y2="3" stroke="white" stroke-width="4" stroke-linecap="round"/></svg> <span class="link">Close</span>',
            prev: '<svg role="img" aria-label="Previous" width="28" height="46" viewBox="0 0 28 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25 3L5 23L25 43" stroke="white" stroke-width="6" stroke-linecap="round"/></svg>',
            next: '<svg role="img" aria-label="Next" width="28" height="46" viewBox="0 0 28 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 3L23 23L3 43" stroke="white" stroke-width="6" stroke-linecap="round"/></svg>',
        }
    });


    // Tippy tool tips
    tippy('[data-tippy-content]', {
        theme: 'custom',
        allowHTML: true,
    });


    // Dropdown navigation
    const subItems = document.querySelectorAll('.has-children');

    if (subItems.length) {
        subItems.forEach(function(e) {
            const subItem = e;
            const subLink = e.firstElementChild;
            // subItem.addEventListener('mouseover', function() {
            //     subLink.setAttribute('aria-expanded', subLink.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            // });
            // subItem.addEventListener('mouseout', function() {
            //     subLink.setAttribute('aria-expanded', subLink.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            // });
        });
    }


    // Mobile navigation
    const navTrigger = document.querySelector('.js-nav-btn');
    const mobNav = document.querySelector('.mobile');
    const mobNavOverlay = document.querySelector('.mobile-overlay');

    if(navTrigger){
        bindEvent(navTrigger, "click", function() {
            event.preventDefault();
            navTopValues();
            document.body.classList.toggle('modal-open');
            navTrigger.classList.toggle('nav-icon--active');
            mobNav.classList.toggle('mobile--active');
            navTrigger.setAttribute('aria-expanded', navTrigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            // mobNav.setAttribute('aria-hidden', mobNav.getAttribute('aria-hidden') === 'true' ? 'false' : 'true');
        }, false);
        window.addEventListener("resize", navTopValues);
    }


    // Get top values for fixed positioning below header
    function navTopValues()  {
        const header = document.querySelector('.header');
        // const navDistanceToTop = window.pageYOffset + header.getBoundingClientRect().bottom;
        const navDistanceToTop = header.getBoundingClientRect().bottom ;
        mobNav.style.top = navDistanceToTop+'px';
        mobNavOverlay.style.top = navDistanceToTop+'px';
    }


    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target;
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    // Glide
    // const mq = window.matchMedia('(max-width: 1023px)');
    // if (mq.matches) {

        const carousels = document.querySelectorAll(".glide");

        Object.values(carousels).map(carousel => {
            const glide = new Glide(carousel, {
                type: 'slider',
                bound: true,
                gap: 0,
                perView: 3,
                peek: {
                    before: 0,
                    after: 0
                },
                breakpoints: {
                    1023: {
                        perView: 2,
                        peek: {
                            before: 0,
                            after: 80
                        }
                    },
                    640: {
                        perView: 1,
                        peek: {
                            before: 0,
                            after: 60
                        }
                    }
                }
            });
            glide.mount();
        });
    // }


    // Lazyload
    const lazy = new LazyLoad({
        elements_selector: ".lazy"
    });


    // Bind event
    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }
})
